import Taro from '@tarojs/taro'

import { getStorageSync, setStorageSync } from './storage'

const authName = `_gd_t_${process.env.TARO_APP_ENV}`

export const saveAuthToken = (token: string) => {
  setStorageSync(authName, { token }, { maxAge: 60 * 60 * 24 * 365 })
}

export const getAuthToken = () => {
  return getStorageSync(authName)?.token
}

export const removeAuthToken = () => {
  Taro.removeStorageSync(authName)
}
