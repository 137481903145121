import { set } from 'lodash-es'
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { getFormTheme } from '@/api'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { Theme } from '@luigi/devkit/graphql/typings'
import type { RootState } from '@/redux/store'

export type FormTheme = Theme & {
  wallpaper: { bgType: 'color' | 'image' }
  formId: string
  welcomePageTheme: { wallpaper: { bgType: 'color' | 'image' } }
}

const formThemeAdapter = createEntityAdapter<FormTheme>({
  selectId: model => model.formId,
})

const formThemeSlice = createSlice({
  name: 'formTheme',
  initialState: formThemeAdapter.getInitialState({}),
  reducers: {
    updateTheme: (state, action: PayloadAction<{ formToken: string; theme: Theme }>) => {
      const theme = state.entities[action.payload.formToken]
      if (!theme) return
      for (const [path, value] of Object.entries(action.payload.theme)) {
        set(theme, path, value)
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getThemeThunk.fulfilled, (state, action) => {
      const { data } = action.payload
      if (data) {
        formThemeAdapter.upsertOne(state, {
          ...data,
          formId: action.meta.arg,
        } as unknown as FormTheme)
      }
    })
  },
})

export const getThemeThunk = createAsyncThunk('formTheme/getThemeThunk', async (token: string) => {
  return await getFormTheme(token)
})

export const formThemeSelectors = formThemeAdapter.getSelectors()

export const selectThemeByToken = createSelector(
  (state: RootState) => state,
  (_state: RootState, token: string) => token,
  (state: RootState, token: string) => formThemeSelectors.selectById(state.formTheme, token)
)

export default formThemeSlice.reducer
