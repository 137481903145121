import LogRocket from 'logrocket'
import dayjs from 'dayjs'

import { isWeappBundle } from '@/utils'
import { useAppSelector } from '@/redux/store'

const LOG_ROCKET_KEYS = [
  'pql2fj/v5-exam',
  'jinshuju-v5-exam-4/jinshuju-v5-exam-4',
  'jinshuju-v5-exam-2/jinshuju-v5-exam-2',
  'jinshuju-v5-exam-3/jinshuju-v5-exam-3',
  'jinshuju-v5-exam-1/jinshuju-v5-exam-1',
]

const useLogRocket = () => {
  const currentUser = useAppSelector(state => state.profile)
  if (process.env.TARO_APP_ENV !== 'production' || !Boolean(process.env.TARO_APP_ENABLE_LOG_ROCKET)) return
  if (isWeappBundle) return
  if (!currentUser.id || dayjs(currentUser.createdAt) < dayjs().subtract(1, 'month')) return

  const logRocketKey = LOG_ROCKET_KEYS[Math.ceil(new Date().getDate() / 6) - 1] || LOG_ROCKET_KEYS[4]
  LogRocket.init(logRocketKey)
  LogRocket.identify(currentUser.id, {
    name: currentUser.name ?? '',
    mobile: currentUser.mobile ?? '',
    createdAt: currentUser.createdAt,
  })
}

export default useLogRocket
