import './lib/sentry/initSentry'
// eslint-disable-next-line import/order
import ReduxProvider from './redux/ReduxProvider'
import Layout from './components/Layout'
import './app.scss'

import type { ReactNode } from 'react'

function AppH5({ children }: { children: ReactNode }) {
  return (
    <ReduxProvider>
      <Layout>{children}</Layout>
    </ReduxProvider>
  )
}

export default AppH5
