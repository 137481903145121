import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import showNotification from '@/utils/showNotification'
import { isWeappBundle } from '@/utils'
import { getCurrentUser, updateUserProfile } from '@/api'

import type { Profile, UpdateProfileArgs } from '@/types'

interface ProfileState extends Profile {
  loading?: boolean
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {} as ProfileState,
  reducers: {
    resetUser: () => ({} as ProfileState),
  },
  extraReducers: builder => {
    builder.addCase(getCurrentUserThunk.pending, state => {
      state.loading = true
    })
    builder.addCase(getCurrentUserThunk.fulfilled, (state, action) => {
      const data = action.payload
      if (!data) return
      if (isWeappBundle) {
        const wxobs = require('@wxobs/miniprogram-helper')
        wxobs.setCustomId(data.id)
        wxobs.setCustomProperties({ userId: data.id, userName: data.name, userMobileNo: data.mobile })
      }
      return { ...state, ...data, loading: false }
    })
    builder.addCase(updateCurrentUserThunk.fulfilled, (state, action) => {
      const data = action.payload
      if (!data) return
      return { ...state, ...data, loading: false }
    })
    builder.addCase(updateCurrentUserThunk.rejected, (_, action) => {
      const msg = action.payload as string
      showNotification('none', msg || '网络错误')
    })
  },
})

export const getCurrentUserThunk = createAsyncThunk('profile/getCurrentUserThunk', async (_, thunkAPI) => {
  const { data, errorMessage, httpStatus } = await getCurrentUser()
  if (httpStatus === 401 || httpStatus === 403) {
    return thunkAPI.rejectWithValue(errorMessage)
  }
  return data
})

export const updateCurrentUserThunk = createAsyncThunk(
  'profile/updateCurrentUserThunk',
  async (args: UpdateProfileArgs, thunkAPI) => {
    const { data, errorMessage, httpStatus } = await updateUserProfile(args)
    if (httpStatus === 401 || httpStatus === 403) {
      return thunkAPI.rejectWithValue(errorMessage)
    }
    return data
  }
)

export const { resetUser } = profileSlice.actions

export default profileSlice.reducer
