import { isNil } from 'lodash-es'

import { QuestionRuleType, QuestionType } from '@/types'

import type { ExamPaperQuestion, ExamPaperRules } from '@luigi/examkit/typings'

export default function parseExamQuestions(rules: ExamPaperRules[]) {
  return rules
    .filter(r => r.type === QuestionRuleType.RandomRule || !!r.question)
    .map(rule => {
      return {
        ...rule.question,
        apiCode: rule.question?.apiCode || rule.apiCode,
        type: rule.type === QuestionRuleType.RandomRule ? QuestionType.RandomQuestions : rule.question?.type,
        questionBankId: rule.questionBankId,
        questionBankToken: rule.questionBankToken,
        score: rule.score,
        count: rule.question ? 1 : rule.randomCount,
        randomQuestionType: rule.questionType,
        hasQuestionBank: !isNil(rule.questionBankId),
      }
    }) as ExamPaperQuestion[]
}
