import Taro from '@tarojs/taro'

import { getAuthToken } from '@/utils/auth'

import type { APIResponse } from './types'

const requestHost = process.env.TARO_APP_PUBLIC_GD_HOST ?? 'http://localhost:3000'

const getAuthTokenHeader = () => {
  const token = getAuthToken()
  return token ? { Authorization: `Bearer ${token}` } : {}
}

const parseData = (data: any) => {
  try {
    return JSON.parse(data)
  } catch (e) {
    return {}
  }
}

export async function taroUpload<T = any>(option: Parameters<typeof Taro.uploadFile>[0]) {
  const { data, statusCode } = await Taro.uploadFile({
    ...option,
    url: `${process.env.TARO_APP_PUBLIC_GD_HOST}${option.url}`,
    header: {
      ...getAuthTokenHeader(),
      ...option.header,
    },
  })
  const response = parseData(data)
  return { ...response, httpStatus: statusCode } as APIResponse<T>
}

export default async function taroRequest<
  T = any,
  U extends string | TaroGeneral.IAnyObject | ArrayBuffer = any | any
>({ body, ...option }: Omit<Taro.request.Option<APIResponse<T>, U>, 'data'> & { body?: U }) {
  const { data, statusCode } = await Taro.request<APIResponse<T>, U>({
    ...option,
    data: body,
    url: `${requestHost}${option.url}`,
    header: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      cache: 'no-store',
      ...getAuthTokenHeader(),
      ...option.header,
    },
  })

  return { ...data, httpStatus: statusCode } as APIResponse<T>
}
