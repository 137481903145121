import { useCallback, useEffect, useMemo } from 'react'
import Taro, { useRouter } from '@tarojs/taro'

import { getAuthToken, removeAuthToken } from '@/utils/auth'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getCurrentUserThunk } from '@/redux/slices/profileSlice'
import useLogRocket from '@/lib/logRocket/useLogRocket'

import type { FC, PropsWithChildren } from 'react'

const whiteListPage = ['/signup', '/home', '/webview', '/gateway', '/customer']

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const currentUserId = useAppSelector(state => state.profile.id)
  const userLoading = useAppSelector(state => state.profile.loading)
  const session = useMemo(() => getAuthToken(), [])
  const noLoginRequired = useMemo(() => whiteListPage.some(i => router.path.includes(i)), [router.path])

  useLogRocket()

  const checkLogin = useCallback(async () => {
    if (userLoading || currentUserId) return

    try {
      await dispatch(getCurrentUserThunk()).unwrap()
    } catch (error) {
      console.error('checkCurrentUser error:', error)
      removeAuthToken()
      Taro.redirectTo({ url: '/pages/signup/index' })
    }
  }, [currentUserId, dispatch, userLoading])

  useEffect(() => {
    if (!session && noLoginRequired) return
    checkLogin()
  }, [checkLogin, noLoginRequired, session])

  return <>{children}</>
}

export default Layout
