import { debounce } from 'lodash-es'

import { batchUpdateExamPaperQuestionsThunk, saveExamPaperQuestionThunk } from '@/redux/slices/examPaperQuestionSlice'
import { saveExaminationThunk } from '@/redux/slices/examinationSlice'
import { store } from '../redux/store'

export const saveExamination = debounce(({ examPaperToken, apiCode }: { examPaperToken: string; apiCode?: string }) => {
  store.dispatch(saveExaminationThunk(examPaperToken))
  store.dispatch(saveExamPaperQuestionThunk(examPaperToken))
  apiCode &&
    store.dispatch(
      batchUpdateExamPaperQuestionsThunk({
        examPaperToken,
        apiCode,
      })
    )
}, 750)

export const saveExaminationOnly = debounce((examPaperToken: string) => {
  store.dispatch(saveExaminationThunk(examPaperToken))
}, 750)
