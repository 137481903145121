import Taro from '@tarojs/taro'

import { getAuthToken } from '@/utils/auth'

export const indicators = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

export const MOBILE_REGEX = /^1[3-9]\d{9}$/

export const isMobile = (value: string) => {
  return MOBILE_REGEX.test(value)
}

export const isVerifyCode = (value: string) => {
  return /^\d{6}$/.test(value)
}

export const isWeixinBrowser = (ua: string) => {
  const lowerUA = ua.toLowerCase()
  return /micromessenger/.test(lowerUA) && !/wxwork/.test(lowerUA)
}

export const bg_url = 'https://gd-pub-v5.jinshujufiles.com/bgi/65bb61cebb0d3a7f26b6b544/20240319180859_a06395'

export const defaultAvatar = 'https://gd-pub-v5.jinshujufiles.com/av/default_user_avatar_1.png'

export const isWeappBundle = process.env.TARO_ENV === 'weapp'

export const safetyViewId = (id: string | number) => `a${id}`

export const getWebviewPath = (url: string) => `/pages/webview/index?url=${encodeURIComponent(url)}`

export const goToH5Page = ({ page, token }: { page: string; token: string }) => {
  const authToken = getAuthToken()
  return getWebviewPath(`${process.env.TARO_APP_ROOT_DOMAIN}/gateway?page=${page}&token=${token}&auth=${authToken}`)
}

export const getStatusBarHeight = () => (Taro.getWindowInfo().statusBarHeight || 0) + 10
