import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { ExamPaperQuestion, QuestionType } from '@luigi/examkit/typings'

export type QuestionPosition = { position: number; apiCode: string; type: QuestionType; total: number }

type SliceState = {
  activeId: string | null
  type: 'question' | 'banner' | null
  questionPositions: QuestionPosition[]
}

const initialState: SliceState = {
  activeId: null,
  type: null,
  questionPositions: [],
}

export const examBuilderSlice = createSlice({
  name: 'examBuilder',
  initialState,
  reducers: {
    changeActiveId: (state, action) => {
      state.activeId = action.payload
    },
    changeType: (state, action) => {
      state.type = action.payload
    },
    changeQuestionMeta: (state, action: PayloadAction<ExamPaperQuestion[]>) => {
      const questions = action.payload
      const { questionPositions } = questions.reduce<{
        questionPositions: QuestionPosition[]
      }>(
        (acc, question, index) => {
          const position = index === 0 ? 0 : acc.questionPositions[index - 1].position
          const total = question.count
          const questionPosition = {
            position: position + total,
            type: question.type,
            apiCode: question.apiCode,
            total,
          }

          acc.questionPositions.push(questionPosition)
          return {
            questionPositions: acc.questionPositions,
          }
        },
        {
          questionPositions: [],
        }
      )
      state.questionPositions = questionPositions
    },
  },
})

export const { changeActiveId, changeType, changeQuestionMeta } = examBuilderSlice.actions

export default examBuilderSlice.reducer
