import { isEmpty } from 'lodash-es'
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import showNotification from '@/utils/showNotification'
import { saveExaminationOnly } from '@/utils/saveExamination'
import HTMLToPlainText from '@/utils/HTMLToPlainText'
import {
  createExamination,
  destroyExamination,
  generateExamDescription,
  getExamination,
  getExaminations,
  updateExamination,
} from '@/api/exam'

import type { EntityState } from '@reduxjs/toolkit/src/entities/models'
import type { APIResponse } from '@luigi/toolkit/utils/execFetchRestfulRequest'
import type { Examination, ExaminationVariables } from '@luigi/examkit/typings'
import type { RootState } from '../store'
import type { LoadingStatus } from './types'

type ExaminationSliceType = EntityState<Examination> & {
  isLoading: boolean
  next: string
  isLoaded: boolean
} & LoadingStatus

const examinationAdapter = createEntityAdapter<Examination>({
  selectId: model => model.token,
})

const initialLinks = '?page[number]=1&page[size]=50'

export const examinationSlice = createSlice({
  name: '$exam',
  initialState: {
    ...examinationAdapter.getInitialState(),
    status: 'idle',
    isLoading: false,
    isLoaded: false,
    next: initialLinks,
  } as ExaminationSliceType,
  reducers: {
    examinationsLoaded: examinationAdapter.upsertMany,
    examinationLoaded: examinationAdapter.upsertOne,
    examinationDestroyed: examinationAdapter.removeOne,
    examinationUpdate: (state, action) => {
      const data = action.payload
      examinationAdapter.updateOne(state, {
        id: data.id,
        changes: data.changes,
      })
      data.examPaperToken && saveExaminationOnly(data.examPaperToken)
    },
    examinationSilentUpdate: examinationAdapter.updateOne,
    examinationCreated: examinationAdapter.addOne,
    unshiftExamination: (state, action) => {
      const data = action.payload
      if (data) {
        if (state.entities[data.token]) {
          examinationAdapter.updateOne(state, { id: data.token, changes: data })
        } else {
          state.ids = [data.token, ...state.ids]
          state.entities[data.token] = data
        }
      }
    },
    reset: state => {
      state.next = initialLinks
    },
    removeAll: state => {
      examinationAdapter.removeAll(state)
      state.isLoaded = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getExaminationsThunk.pending, state => {
      state.isLoading = true
      state.status = 'pending'
    })
    builder.addCase(getExaminationsThunk.fulfilled, (state, action) => {
      const { data, links } = action.payload
      const self = decodeURI(links?.self ?? '')
      if (self.includes('page[number]=1') && !isEmpty(state.ids)) {
        examinationAdapter.removeAll(state)
      }
      examinationAdapter.upsertMany(state, data || [])
      state.next = links?.next ?? ''
      state.isLoading = false
      state.isLoaded = true
      state.status = 'fulfilled'
    })
    builder.addCase(createExaminationThunk.fulfilled, (state, action) => {
      const { data } = action.payload
      if (data) {
        if (state.entities[data.token]) {
          examinationAdapter.updateOne(state, { id: data.token, changes: data })
        } else {
          state.ids = [data.token, ...state.ids]
          state.entities[data.token] = data
        }
      }
    })
    builder.addCase(getExaminationsThunk.rejected, (state, action) => {
      const { errorMessage } = action.payload as APIResponse<unknown>
      state.status = 'rejected'
      state.isLoading = false
      state.next = ''
      showNotification('error', errorMessage || '系统异常!')
    })
    builder.addCase(createExaminationThunk.rejected, (_, action) => {
      const msg = action.payload as string
      showNotification('error', msg || '保存失败!')
    })
    builder.addCase(updateExaminationThunk.rejected, (_, action) => {
      const msg = action.payload as string
      showNotification('error', msg || '保存失败!')
    })
  },
})

export const getExaminationsThunk = createAsyncThunk('$exam/getExaminationsThunk', async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState
  const response = await getExaminations(state.examinations.next)
  if (response.httpStatus !== 200) {
    return thunkApi.rejectWithValue(response)
  } else {
    thunkApi.dispatch(examinationsLoaded(response.data || []))
  }
  return response
})

export const getExaminationThunk = createAsyncThunk('$exam/getExaminationThunk', async (token: string, thunkApi) => {
  const response = await getExamination({ token: token })
  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors[0].message)
  } else {
    response.data &&
      thunkApi.dispatch(
        examinationLoaded({
          ...response.data,
          name: HTMLToPlainText(response.data.name || ''),
          description: HTMLToPlainText(response.data.description || ''),
        })
      )
  }
  return response
})

export const createExaminationThunk = createAsyncThunk<APIResponse<Examination>, ExaminationVariables>(
  '$exam/createExaminationThunk',
  async (payload, thunkApi) => {
    const { data, errors } = await createExamination(payload)
    if (errors) {
      return thunkApi.rejectWithValue(errors[0].message)
    }
    return { data, errors }
  }
)

export const generateExamDescriptionThunk = createAsyncThunk(
  '$exam/updateExaminationDescriptionThunk',
  async (token: string, thunkApi) => {
    const { data, errors } = await generateExamDescription({ token: token })
    if (errors) {
      return thunkApi.rejectWithValue(errors[0].message)
    } else {
      thunkApi.dispatch(examinationSilentUpdate({ id: token, changes: { description: data!.description } }))
    }
    return { data, errors }
  }
)

export const updateExaminationThunk = createAsyncThunk<APIResponse<Examination>, ExaminationVariables>(
  '$exam/updateExaminationThunk',
  async (payload, thunkApi) => {
    const { data, errors } = await updateExamination(payload)
    if (errors) {
      thunkApi.rejectWithValue(errors[0].message)
    } else {
      thunkApi.dispatch(examinationUpdate({ id: data!.token, changes: data! }))
    }
    return { data, errors }
  }
)

export const saveExaminationThunk = createAsyncThunk<APIResponse<Examination>, string, { state: RootState }>(
  '$exam/saveExaminationThunk',
  async (examPaperToken, thunkApi) => {
    const exams = examinationSelectors.selectAll(thunkApi.getState().examinations)
    const exam = exams.find(exam => exam.examPaperToken === examPaperToken)
    const { data, errors } = await updateExamination({
      token: exam?.token,
      name: exam?.name,
      description: exam?.description,
    })
    if (errors) {
      return thunkApi.rejectWithValue(errors[0].message)
    }
    return { data, errors }
  }
)

export const destroyExaminationThunk = createAsyncThunk<APIResponse<null>, string>(
  '$exam/destroyExaminationThunk',
  async (token, thunkApi) => {
    const { data, errors } = await destroyExamination({ token: token })
    if (errors) {
      return thunkApi.rejectWithValue(errors[0])
    } else {
      thunkApi.dispatch(examinationDestroyed(token))
    }
    return { data, errors }
  }
)

export const examinationSelectors = examinationAdapter.getSelectors()

export const selectExamPaperToken = createSelector(
  (state: RootState) => state.examinations,
  (_: RootState, token: string) => token,
  (state, token) => {
    return examinationSelectors.selectById(state, token)?.examPaperToken ?? ''
  }
)

export const selectExaminationByToken = createSelector(
  (state: RootState) => state.examinations,
  (_: RootState, token: string) => token,
  (state, token) => {
    return examinationSelectors.selectById(state, token)
  }
)

export const {
  examinationsLoaded,
  examinationLoaded,
  examinationDestroyed,
  examinationUpdate,
  examinationSilentUpdate,
  reset,
  removeAll,
  unshiftExamination,
} = examinationSlice.actions

export default examinationSlice.reducer
