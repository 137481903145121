import { createSlice } from '@reduxjs/toolkit'

import { safetyViewId } from '@/utils'

import type { PayloadAction } from '@reduxjs/toolkit'

export enum TabBar {
  Home = 'home',
  Profile = 'profile',
}

interface UIState {
  currentTab: TabBar
  smsCoolingTime?: number
  questionFocusId?: string | null
  isMiniApp: boolean
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: { currentTab: TabBar.Home, isMiniApp: false } as UIState,
  reducers: {
    updateTabBar: (state, action: PayloadAction<TabBar>) => {
      state.currentTab = action.payload
    },
    setSmsCoolingTime: (state, action: PayloadAction<number | undefined | null>) => {
      state.smsCoolingTime = action.payload ?? undefined
    },
    changeQuestionFocusId: (state, action: PayloadAction<UIState['questionFocusId']>) => {
      state.questionFocusId = action.payload ? safetyViewId(action.payload) : null
    },
    changeMiniApp: (state, action: PayloadAction<boolean>) => {
      state.isMiniApp = action.payload
    },
  },
})

export const { updateTabBar, setSmsCoolingTime, changeQuestionFocusId, changeMiniApp } = uiSlice.actions

export default uiSlice.reducer
