import { cloneDeep } from 'lodash-es'
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { getEntries } from '@/api'

import type { EntityState } from '@reduxjs/toolkit'
import type { Entry } from '@luigi/formkit/src/types/entryType'
import type { RootState } from '@/redux/store'

const entryAdapter = createEntityAdapter<Entry>({
  selectId: model => model.serialNumber,
})

type EntriesState = EntityState<Entry> & {
  status: 'idle' | 'loading' | 'succeeded'
  isLoaded: boolean
  totalCount: number
  endCursor?: string | null
  hasNextPage: boolean
}

type EntriesSliceState = Record<string, EntriesState>

const initialEntriesState: EntriesState = entryAdapter.getInitialState({
  status: 'idle',
  isLoaded: false,
  totalCount: 0,
  hasNextPage: false,
})

const entriesSlice = createSlice({
  name: 'entries',
  initialState: {} as EntriesSliceState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEntriesThunk.pending, (state, action) => {
        const { token } = action.meta.arg
        state[token] ||= cloneDeep(initialEntriesState)
        state[token].status = 'loading'
      })
      .addCase(getEntriesThunk.fulfilled, (state, action) => {
        const { token } = action.meta.arg
        const data = action.payload
        state[token].status = 'succeeded'
        state[token].isLoaded = true
        state[token].totalCount = data.totalCount
        entryAdapter.addMany(state[token], data)
      })
  },
})

export const getEntriesThunk = createAsyncThunk(
  'entries/getEntries',
  async ({ token }: { token: string }, thunkApi) => {
    const { data, errors } = await getEntries(token)
    if (errors) {
      return thunkApi.rejectWithValue(errors)
    }
    return data
  }
)

const entrySelectors = entryAdapter.getSelectors()

export const selectEntries = createSelector(
  (state: RootState) => state.entries,
  (_state: RootState, token: string) => token,
  (state, token) => {
    if (!state[token]) {
      return []
    }
    return entrySelectors.selectAll(state[token])
  }
)

export default entriesSlice.reducer
