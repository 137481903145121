import { isNil } from 'lodash-es'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { ContentType, CreationType, OutPutLanguage } from '@/types'
import { getExamAIUsages } from '@/api'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AIUsage } from '@luigi/examkit/typings'
import type { RootState } from '../store'

interface AICreationState {
  tempPrompt?: string
  prompt?: string
  jobId?: string
  channelToken?: string
  loadWithPrompt?: boolean
  withDeepMode?: boolean
  withCache?: boolean
  creationType?: CreationType
  contentType?: ContentType
  outPutLanguage?: OutPutLanguage
  usage?: AIUsage
  creationFailReason?: string
  documentFile?: {
    name: string
    path: string
  }
}

export const aiCreation = createSlice({
  name: 'creating',
  initialState: {} as AICreationState,
  reducers: {
    updateCreationState: (
      state,
      action: PayloadAction<Pick<AICreationState, 'channelToken' | 'jobId' | 'prompt' | 'withDeepMode' | 'withCache'>>
    ) => {
      const { prompt, channelToken, jobId, withDeepMode, withCache } = action.payload
      !isNil(prompt) && (state.prompt = prompt)
      !isNil(channelToken) && (state.channelToken = channelToken)
      !isNil(jobId) && (state.jobId = jobId)
      !isNil(withDeepMode) && (state.withDeepMode = withDeepMode)
      !isNil(withCache) && (state.withCache = withCache)
    },
    clearChannelAndJob: state => {
      state.channelToken = undefined
      state.jobId = undefined
    },
    updateTempPrompt: (state, action: PayloadAction<string | null>) => {
      state.tempPrompt = action.payload ?? ''
    },
    setLoadWithPrompt: (state, action: PayloadAction<boolean>) => {
      state.loadWithPrompt = action.payload
    },
    setDocumentFile: (state, action: PayloadAction<Exclude<AICreationState['documentFile'], undefined>>) => {
      state.documentFile = action.payload
    },
    clearDocumentFile: state => {
      if (!state.documentFile) return
      state.documentFile = undefined
    },
    resetCreation: state => {
      state.prompt = undefined
      state.creationType = undefined
      state.contentType = undefined
      state.outPutLanguage = undefined
      state.withDeepMode = undefined
      state.withCache = undefined
      state.creationFailReason = undefined
    },
    setCreationAndContentType: (
      state,
      action: PayloadAction<{ creationType?: CreationType; contentType?: ContentType; outPutLanguage?: OutPutLanguage }>
    ) => {
      state.creationType = action.payload.creationType ?? CreationType.OneLine
      state.contentType = action.payload.contentType ?? ContentType.Other
      state.outPutLanguage = action.payload.outPutLanguage ?? OutPutLanguage.Chinese
    },
    setCreationFailReason: (
      state,
      action: PayloadAction<Exclude<AICreationState['creationFailReason'], undefined>>
    ) => {
      state.creationFailReason = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getAIUsagesThunk.fulfilled, (state, action) => {
      state.usage = action.payload
    })
  },
})

export const getAIUsagesThunk = createAsyncThunk('aiCreation/getUsagesThunk', async () => {
  const { data } = await getExamAIUsages()
  return data
})

export const selectIsDeepModeOrEducation = createSelector(
  (state: RootState) => state.aiCreation.withDeepMode,
  (state: RootState) => state.aiCreation.contentType === ContentType.Education,
  (deepMode, eductionContent) => deepMode || eductionContent
)

export const {
  updateCreationState,
  clearChannelAndJob,
  updateTempPrompt,
  setLoadWithPrompt,
  setDocumentFile,
  clearDocumentFile,
  resetCreation,
  setCreationAndContentType,
  setCreationFailReason,
} = aiCreation.actions

export default aiCreation.reducer
