import { useDispatch, useSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

import uiReducer from './slices/uiSlice'
import profileSliceReducer from './slices/profileSlice'
import formThemeReducer from './slices/formThemeSlice'
import examPaperQuestionSliceReducer from './slices/examPaperQuestionSlice'
import examinationReducer from './slices/examinationSlice'
import examBuilderReducer from './slices/examBuilderSlice'
import entriesReducer from './slices/entriesSlice'
import aiCreationReducer from './slices/aiCreationSlice'

import type { TypedUseSelectorHook } from 'react-redux'

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    examinations: examinationReducer,
    aiCreation: aiCreationReducer,
    profile: profileSliceReducer,
    examPaperQuestions: examPaperQuestionSliceReducer,
    formTheme: formThemeReducer,
    examBuilder: examBuilderReducer,
    entries: entriesReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
