import { isUndefined } from 'lodash-es'
import * as Sentry from '@sentry/react'

const initSentryConfig = () => {
  if (process.env.TARO_APP_ENV !== 'production' || isUndefined(process.env.TARO_APP_SENTRY_DSN)) return
  Sentry.init({
    sampleRate: 0.1,
    dsn: process.env.TARO_APP_SENTRY_DSN,
    allowUrls: [/jinshuju/i],
    denyUrls: [/extensions\//i, /^chrome:\/\//i],
    environment: process.env.TARO_APP_ENV,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  })
}

initSentryConfig()
