import { cloneDeep, isEmpty, pick } from 'lodash-es'
import Decimal from 'decimal.js'
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { saveExamination } from '@/utils/saveExamination'
import parseExamQuestions from '@/utils/parseExamQuestions'
import { QuestionRuleType, QuestionType } from '@/types'
import { batchUpdateQuestions, getExamPaperByToken, updatePaper } from '@/api/exam'

import type { EntityState, PayloadAction } from '@reduxjs/toolkit'
import type {
  ExamPaper,
  ExamPaperQuestion,
  ExamPaperRules,
  Question,
  UpdateQuestionVariables,
} from '@luigi/examkit/typings'
import type { RootState } from '@/redux/store'
import type { APIResponse } from '@/lib/network/types'

type SliceState = Record<string, EntityState<ExamPaperQuestion>> & {
  isLoaded: boolean
}

const examPaperQuestionAdapter = createEntityAdapter<ExamPaperQuestion>({
  selectId: model => model.apiCode,
})

export const examPaperQuestionSelectors = examPaperQuestionAdapter.getSelectors()

export const examPaperQuestionSlice = createSlice({
  name: 'examPaperQuestion',
  initialState: {
    isLoaded: false,
  } as SliceState,
  reducers: {
    removeOne: (state, action: PayloadAction<{ examPaperToken: string; apiCode: string }>) => {
      const { examPaperToken, apiCode } = action.payload
      const examPaperQuestionEntityState = state[examPaperToken]
      examPaperQuestionAdapter.removeOne(examPaperQuestionEntityState, apiCode)
      saveExamination(action.payload)
    },
    updateOne: (
      state,
      action: PayloadAction<{ examPaperToken: string; apiCode: string; changes: Partial<ExamPaperQuestion> }>
    ) => {
      const { examPaperToken, apiCode, changes } = action.payload
      const examPaperQuestionEntityState = state[examPaperToken]
      examPaperQuestionAdapter.updateOne(examPaperQuestionEntityState, { id: apiCode, changes })
      saveExamination(action.payload)
    },
    moveTo: (state, action: PayloadAction<{ examPaperToken: string; from: string; to: string }>) => {
      const { examPaperToken, from, to } = action.payload
      const examPaperQuestionEntityState = state[examPaperToken]
      const currentIndex = examPaperQuestionEntityState.ids.indexOf(from)
      const destinationIndex = examPaperQuestionEntityState.ids.indexOf(to)
      if (currentIndex !== destinationIndex) {
        examPaperQuestionEntityState.ids.splice(currentIndex, 1)
        examPaperQuestionEntityState.ids.splice(destinationIndex, 0, from)
      }
      saveExamination(action.payload)
    },
    addOneAfter: (
      state,
      action: PayloadAction<{ examPaperToken: string; apiCode: string; question: ExamPaperQuestion }>
    ) => {
      const { question, examPaperToken, apiCode } = action.payload
      const examPaperQuestionEntityState = state[examPaperToken]
      examPaperQuestionAdapter.addOne(examPaperQuestionEntityState, question)
      const currentIndex = examPaperQuestionEntityState.ids.indexOf(question.apiCode)
      let targetIndex: number
      if (apiCode) {
        const afterIndex = examPaperQuestionEntityState.ids.indexOf(apiCode) + 1
        targetIndex = afterIndex > currentIndex ? afterIndex - 1 : afterIndex
      } else {
        targetIndex = examPaperQuestionEntityState.ids.length - 1
      }
      if (currentIndex !== targetIndex) {
        const [removed] = examPaperQuestionEntityState.ids.splice(currentIndex, 1)
        examPaperQuestionEntityState.ids.splice(targetIndex, 0, removed)
      }
      saveExamination(action.payload)
    },
    setAllQuestions: (state, action: PayloadAction<{ token: string; rules: ExamPaperRules[] }>) => {
      const { rules, token } = action.payload
      if (!state[token]) {
        state[token] = cloneDeep(examPaperQuestionAdapter.getInitialState())
      }
      examPaperQuestionAdapter.setAll(state[token], parseExamQuestions(rules))
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getExamPaperQuestionsThunk.pending, (state, action) => {
        const token = action.meta.arg
        const examPaperQuestionEntityState = state[token]
        if (!examPaperQuestionEntityState) {
          state[token] = cloneDeep(examPaperQuestionAdapter.getInitialState())
        }
      })
      .addCase(getExamPaperQuestionsThunk.fulfilled, (state, action) => {
        const token = action.meta.arg
        const data = action.payload.data
        state.isLoaded = true
        if (data) {
          const examPaperQuestionEntityState = state[token]
          examPaperQuestionAdapter.setAll(examPaperQuestionEntityState, parseExamQuestions(data.rules))
        }
      })
      .addCase(batchUpdateExamPaperQuestionsThunk.fulfilled, (state, action) => {
        const { examPaperToken } = action.meta.arg
        const { data } = action.payload
        if (!data) return
        const examPaperQuestionEntityState = state[examPaperToken]
        const questions = data?.questions?.map(question => {
          return {
            id: question.apiCode,
            changes: {
              id: question.id,
            },
          }
        })
        examPaperQuestionAdapter.updateMany(examPaperQuestionEntityState, questions)
      })
  },
})

export const getExamPaperQuestionsThunk = createAsyncThunk<APIResponse<ExamPaper>, string, { state: RootState }>(
  `examPaperQuestion/getExamPaperQuestionsThunk`,
  async (token: string, thunkAPI) => {
    const response = await getExamPaperByToken({ token })
    if (response.errors) {
      thunkAPI.rejectWithValue(response.errors[0].message)
    }
    return response
  }
)

export const saveExamPaperQuestionThunk = createAsyncThunk<APIResponse<ExamPaper>, string, { state: RootState }>(
  `examPaperQuestion/saveExamPaperQuestionThunk`,
  async (examPaperToken: string, thunkAPI) => {
    const questions = selectExamPaperQuestionsByExamPaperToken(thunkAPI.getState(), examPaperToken)
    const { totalScore, questionsCount } = questions.reduce(
      (acc, cur) => {
        acc.totalScore = new Decimal(cur.score).mul(cur.count).add(acc.totalScore).toNumber()
        acc.questionsCount = acc.questionsCount + cur.count
        return acc
      },
      {
        totalScore: 0,
        questionsCount: 0,
      }
    )
    const rules = questions.map(question => {
      if (question.type === QuestionType.RandomQuestions) {
        return {
          type: QuestionRuleType.RandomRule,
          apiCode: question.apiCode,
          questionBankId: question.questionBankId,
          questionType: question.randomQuestionType!,
          score: question.score,
          randomCount: question.count,
        }
      } else {
        return {
          type: QuestionRuleType.FixedRule,
          apiCode: question.apiCode,
          questionBankId: question.questionBankId,
          questionApiCode: question.apiCode,
          questionType: question.type,
          score: question.score,
        }
      }
    })
    const response = await updatePaper({
      token: examPaperToken,
      totalScore,
      questionsCount,
      rules,
    })
    if (response.errors) {
      return thunkAPI.rejectWithValue(response.errors[0].message)
    }
    return response
  }
)

export const batchUpdateExamPaperQuestionsThunk = createAsyncThunk<
  APIResponse<{ questions: Question[] }>,
  { examPaperToken: string; apiCode: string },
  { state: RootState }
>(`examPaperQuestion/batchUpdatePaperQuestionsThunk`, async (payload, thunkAPI) => {
  const question = selectExamPaperQuestionById(thunkAPI.getState(), payload.examPaperToken, payload.apiCode)
  const batchQuestions: UpdateQuestionVariables[] = []
  if (question) {
    const newQuestion = pick(question, [
      'id',
      'questionBankId',
      'apiCode',
      'label',
      'explanation',
      'correctAnswers',
      'correctAnswersUnordered',
      'choices',
      'type',
    ])
    batchQuestions.push(newQuestion)
  }
  if (isEmpty(batchQuestions)) {
    thunkAPI.rejectWithValue('没有找到试题')
  }
  const response = await batchUpdateQuestions(batchQuestions)
  if (response.errors) {
    thunkAPI.rejectWithValue(response.errors[0].message)
  }
  return response
})

export const selectExamPaperQuestionsByExamPaperToken = createSelector(
  (state: RootState) => state.examPaperQuestions,
  (_state: RootState, token: string) => token,
  (state, token) => {
    if (state[token]) {
      return examPaperQuestionSelectors.selectAll(state[token])
    } else {
      return []
    }
  }
)

export const selectExamPaperQuestionIds = createSelector(
  (state: RootState) => state.examPaperQuestions,
  (_state: RootState, token: string) => token,
  (state, token) => {
    if (state[token]) {
      return examPaperQuestionSelectors.selectIds(state[token])
    } else {
      return []
    }
  }
)

export const selectExamPaperQuestionById = createSelector(
  (state: RootState) => state.examPaperQuestions,
  (_state: RootState, examPaperToken: string) => examPaperToken,
  (_state: RootState, _examPaperToken: string, id: string) => id,
  (state, examPaperToken, id) => {
    return examPaperQuestionSelectors.selectById(state[examPaperToken], id)
  }
)

export const { updateOne, removeOne, moveTo, addOneAfter, setAllQuestions } = examPaperQuestionSlice.actions

export default examPaperQuestionSlice.reducer
