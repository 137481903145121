import Taro from '@tarojs/taro'

interface StorageOptions {
  /** 有效时长(秒) */
  maxAge?: number
}

interface StorageData extends StorageOptions {
  data: unknown
  __taroCreatedAt: number
}

export const setStorageSync = (key: string, data: unknown, options?: StorageOptions) => {
  Taro.setStorageSync(key, { ...options, data, __taroCreatedAt: Date.now() } as StorageData)
}

export const getStorageSync = (key: string) => {
  const storage = Taro.getStorageSync(key)
  if (!storage || typeof storage !== 'object' || !('__taroCreatedAt' in storage)) return storage

  const { data, maxAge, __taroCreatedAt: createdAt } = storage as StorageData
  if (!maxAge) return data
  if (Date.now() - createdAt < maxAge * 1000) return data
  Taro.removeStorageSync(key)
  return null
}
