import { omit } from 'lodash-es'
import Cookies from 'js-cookie'

import { getStoredUtm } from '@/utils/utmRecorder'
import taroRequest from '@/lib/network/taroRequest'

import type { AIUsage } from '@luigi/examkit/typings'
import type { Theme } from '@luigi/devkit/graphql/typings'
import type { Profile, UpdateProfileArgs } from '@/types'
import type { GeetestType, GeeTestValidateResult } from '@/lib/geetest/types'

function registerReferrer() {
  const searchParams = new URL(window.location.href).searchParams
  const referrerUrl = Cookies.get('utm_referrer') || document.referrer
  const utm: Map<string, string | undefined> = new Map()
  const _cookies = Cookies.get()
  Object.keys(_cookies).forEach(key => {
    if (key.indexOf('utm_') === 0 && key != 'utm_referrer') {
      utm.set(key.split('_')[1], _cookies[key])
    }
  })
  searchParams.forEach((value, key) => {
    if (key.indexOf('utm_') === 0) {
      utm.set(key.split('_')[1], value)
    }
  })
  return {
    referrer_url: referrerUrl,
    utm: Object.fromEntries(utm),
  }
}

export const getSMSCode = async ({
  mobileNo,
  geetestValidateResult,
}: {
  mobileNo: string
  geetestValidateResult: GeeTestValidateResult
}) => {
  return await taroRequest<{ coolingTime: number }>({
    url: '/api/v5/sms_code',
    method: 'POST',
    body: {
      mobile: mobileNo,
      geetest_data: { ...omit(geetestValidateResult, 'captcha_id') },
    },
  })
}

export const getGeeTestInitializationData = async (type: GeetestType) => {
  return await taroRequest<{ geetest: { id: string } }>({
    url: `/api/v5/geetest?type=${type}`,
    method: 'GET',
  })
}

export const signUp = async (
  body: {
    mobile: string
    sms_code: string
  },
  geetestValidateResult: GeeTestValidateResult
) => {
  return await taroRequest<{ token: string }>({
    url: '/api/v5/signup',
    method: 'POST',
    body: {
      ...body,
      register_referrer: registerReferrer(),
      geetest_data: { ...omit(geetestValidateResult, 'captcha_id') },
    },
  })
}

export const getCurrentUser = async () =>
  await taroRequest<Profile>({
    method: 'GET',
    url: '/api/v5/current_user',
  })

export const getWeixinOfficialAccountOauthUrl = async () => {
  const { data } = await taroRequest<{ oauth_url: string }>({
    method: 'GET',
    url: '/v5/oauth/weixin_official_account/new',
  })
  return data
}

export async function bindMobile({
  mobile,
  smsCode,
  geetestData,
  oauthCode,
}: {
  mobile: string
  smsCode: string
  geetestData: GeeTestValidateResult
  oauthCode: string | null
}) {
  return await taroRequest<{ token: string }>({
    url: '/api/v5/bind_mobile',
    method: 'POST',
    body: {
      mobile,
      sms_code: smsCode,
      geetest_data: geetestData,
      oauth_code: oauthCode,
    },
  })
}

export const getFormTheme = async (token: string) =>
  await taroRequest<{ theme: Theme }>({
    method: 'GET',
    url: `/api/v5/forms/${token}/theme`,
  })

export const updateFormSetting = async (token: string, body: { manuallyCloseRule: { closed: boolean } }) =>
  await taroRequest({
    method: 'PUT',
    url: `/api/v5/forms/${token}/setting`,
    body,
  })

export const getEntries = async (token: string) =>
  await taroRequest({
    method: 'GET',
    url: `/api/v5/forms/${token}/entries`,
  })

export const redeemPromoCode = async (body: { code: string }) =>
  await taroRequest({
    method: 'POST',
    url: '/api/v5/promotion/redeem_promo_codes',
    body,
  })

export const updateUserProfile = async (body: UpdateProfileArgs) =>
  await taroRequest<Profile>({
    method: 'PUT',
    url: `/api/v5/current_user`,
    body,
  })

export const weappSignIn = async ({ code }: { code: string }) =>
  await taroRequest<{ token?: string }>({
    method: 'GET',
    url: `/api/v5/weixin/mini_program/sign_in?code=${code}`,
  })

export const weappPhoneNumberSignUp = async (body: { code: string; jscode: string }) =>
  await taroRequest<{ token?: string }>({
    url: `/api/v5/weixin/mini_program/sign_up`,
    method: 'POST',
    body: {
      ...body,
      register_referrer: getStoredUtm(),
    },
  })

export const weappSMSSignUp = async (
  body: {
    mobile: string
    sms_code: string
  },
  geetestValidateResult: GeeTestValidateResult
) => {
  return await taroRequest<{ token: string }>({
    url: '/api/v5/signup',
    method: 'POST',
    body: {
      ...body,
      register_referrer: getStoredUtm(),
      geetest_data: { ...omit(geetestValidateResult, 'captcha_id') },
    },
  })
}

export const getExamAIUsages = async () => {
  return await taroRequest<AIUsage>({
    url: '/exam/ai/usages',
    method: 'GET',
  })
}
